import React from 'react'
import { TarBrokerFeaturesProps } from './ArBrokerFeatures.types'
import BrokersImage1 from 'src/assets/images/brokers-image-1.png'
import BrokersImage2 from 'src/assets/images/brokers-image-2.png'
import BrokersImage3 from 'src/assets/images/brokers-image-3.png'
import BrokersImage4 from 'src/assets/images/brokers-image-4.png'
import BrokersImage5 from 'src/assets/images/brokers-image-5.png'
import { ArBrokerCustomerLove } from 'src/components/ArBrokerCustomerLove'
import { ArFeatureBlock } from 'src/components/ArFeatureBlock'

export const ArBrokerFeatures: React.FC<TarBrokerFeaturesProps> = () => {

  return (
    <>
      <ArFeatureBlock
        title="Efficient risk marketing"
        description={
          <p>
            The future of risk marketing is seamless, efficient and digital. Place risk faster by connecting digitally to all types of markets.
            <br/>
            <br/>
            Effortlessly structure customer data and overlay additional insights for your capacity providers, freeing brokers to focus on delivering better client outcomes—not on time-consuming manual processes.
          </p>
        }
        imageUrl={BrokersImage1}
        imageAlt="Screenshot of artificial's broker tool user interface"
        isImageLeft={true}
      />

      <ArFeatureBlock
        title="Optimise your placement strategies"
        description={
          <p>
            Our tools remove the complexities of matching multi-layer risks with multiple capacity providers, simplifying the process from start to finish.
            <br/>
            <br/>
            Make data-driven decisions, from capacity selection to line size calculations, ensuring precision and efficiency across your portfolio. Gain deeper insights into performance and risk, empowering you to deliver exceptional client results every time.
          </p>
        }
        imageUrl={BrokersImage2}
        imageAlt="Screenshot of artificial's broker tool user interface"
        hasGrayBackground={true}
      />

      <ArFeatureBlock
        title="Capture structured data"
        description={
          <p>
            Build a valuable and comprehensive data record from the very first interaction with your clients and distribution partners.
            <br/>
            <br/>
            AI-powered data ingestion allows for automated processes, data-driven decision-making, and seamless collaboration across teams.
            <br/>
            <br/>
            We automate workflows, oversight, management, and compliance, freeing brokers to focus on delivering better client outcomes.
          </p>
        }
        imageUrl={BrokersImage3}
        imageAlt="Screenshot of artificial's broker tool user interface"
        isImageLeft={true}
      />

      <ArFeatureBlock
        title="Build data-first contracts"
        description={
          <p>
            Our market-leading Contract Builder tool creates a more accurate, consistent and efficient broker workflow by combining your risk model, data points and legal documents with powerful editing and formatting tools.
            <br/>
            <br/>
            Effortlessly generate data-first, market-compliant contracts to your data model specifications and use structured data to automate processes and operations.
          </p>
        }
        imageUrl={BrokersImage4}
        imageAlt="Screenshot of artificial's broker tool user interface"
        hasGrayBackground={true}
      />

      <ArFeatureBlock
        title="Digitise your facilities"
        description={
          <p>
            Our one-of-a-kind Smart Facilities tool allows you to streamline your operations and improve accuracy with seamless capacity allocation and intuitive, data-driven line deployment.
            <br/>
            <br/>
            Our toolkit is the only technology in the market that can capture multiple underwriting rules digitally within your system to remove the manual effort of placing risk into a facility.
          </p>
        }
        imageUrl={BrokersImage5}
        imageAlt="Screenshot of artificial's broker tool user interface"
        isImageLeft={true}
      />

      <ArBrokerCustomerLove />
    </>
  )
}
