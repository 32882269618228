import React from 'react'
import { ArContainer } from 'src/components/ArContainer'
import { TArHeroProps } from './ArHero.types'
import { ArButton } from 'src/components/ArButton'
import * as s from './ArHero.module.scss'

export const ArHero: React.FC<TArHeroProps> = ({
  title,
  description,
  buttonText,
  buttonHref,
  heroImageSrc,
  heroImageAlt,
  logosTitle,
  logosNode,
}) => {

  return (
    <ArContainer className={s.container}>
      <div className="text-center mx-auto flex flex-col">
        <div className="text-center mx-auto max-w-3xl mb-6">
          <h1>{title}</h1>
        </div>
        <div className="max-w-4xl mx-auto mb-8">
          {description}
        </div>
        <div className="flex justify-center mb-8">
          <a href={buttonHref}>
            <ArButton theme="white-outlined">{buttonText}</ArButton>
          </a>
        </div>
        <div className="max-w-6xl lg:py-8 lg:pb-16 py-8">
          <img
            src={heroImageSrc}
            alt={heroImageAlt}
            className="w-[1652px] h-[903px]"
          />
        </div>
        {(logosTitle || logosNode) && (
          <>
            {logosTitle && (
              <h6 className="uppercase font-mono text-brandGray-500 text-sm leading-5 text-center tracking-widest lg:pt-2 md:mb-12">
                {logosTitle}
              </h6>
            )}
            {logosNode}
          </>
        )}
      </div>
    </ArContainer>
  )
}
