import React from 'react'
import { ArHero } from 'src/components/ArHero'
import BrokerpageScreenshot from 'src/assets/images/artificial-contract-builder-tool.png'
import LogoLockton from 'src/assets/svg/logo-lockton.svg'
import LogoBms from 'src/assets/svg/logo-bms.svg'
import LogoAon from 'src/assets/svg/logo-aon.svg'
import LogoGallagher from 'src/assets/svg/logo-gallagher.svg'
import LogoArdonagh from 'src/assets/svg/logo-ardonagh.svg'
import { ROUTES } from 'src/config/routes'

export const ArBrokerHero: React.FC = () => {
  const logosNode = (
    <div className="max-w-5xl mx-auto grid md:grid-cols-5 grid-cols-2 pt-12 md:pt-0 justify-items-center items-center gap-15">
      <LogoAon aria-label="Aon insurance logo"/>
      <LogoGallagher aria-label="Gallagher insurance logo" />
      <LogoLockton aria-label="Lockton insurance logo" />
      <LogoBms aria-label="BMS insurance logo" />
      <LogoArdonagh className="col-span-2 md:col-span-1" aria-label="Ardonagh insurance logo" />
    </div>
  )

  return (
    <ArHero
      title="The future of risk placement"
      description={
        <p>
          Artificial's <span className="font-semibold">Smart Placement</span> technology is the only fully-configurable toolkit that creates value through truly digital placement.
        </p>
      }
      buttonText="Get in touch"
      buttonHref={ROUTES.contact()}
      heroImageSrc={BrokerpageScreenshot}
      heroImageAlt="Screenshot of artificial's broker tool user interface"
      logosTitle="TRUSTED BY SOME OF THE WORLD'S BEST BROKERS"
      logosNode={logosNode}
    />
  )
}
