import React from 'react'
import { ArContainer } from 'src/components/ArContainer'
import { TArFeatureBlockProps } from './ArFeatureBlock.types'
import * as s from './ArFeatureBlock.module.scss'

export const ArFeatureBlock: React.FC<TArFeatureBlockProps> = ({
  title,
  description,
  imageUrl,
  imageAlt,
  isImageLeft = false,
  hasGrayBackground = false,
  className = '',
}) => {
  return (
    <div className={`w-full ${hasGrayBackground ? 'ar-gradient-light' : ''}`}>
      <ArContainer className={`${s.container} ${className}`}>
        <div className={`flex flex-col ${isImageLeft ? 'lg:flex-row-reverse' : 'lg:flex-row'} w-full gap-12 items-center ${!isImageLeft ? 'my-6' : ''}`}>
          <div className={`flex flex-col max-w-xl ${isImageLeft ? 'lg:pl-15' : 'lg:pr-15'}`}>
            <h2>{title}</h2>
            <div className="flex flex-col gap-3 my-auto">
              {description}
            </div>
          </div>
          <div className={`max-w-lg lg:max-w-none lg:w-full ${isImageLeft ? '-my-6 md:-my-0' : ''}`}>
            <img src={imageUrl} loading="lazy" alt={imageAlt} />
          </div>
        </div>
      </ArContainer>
    </div>
  )
}
