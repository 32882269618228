// extracted by mini-css-extract-plugin
export var arGradientBlue = "ArHero-module--ar-gradient-blue--k4Vma";
export var arGradientLight = "ArHero-module--ar-gradient-light--KwMzc";
export var arH1 = "ArHero-module--ar-h1--ywo-N";
export var arH1Mobile = "ArHero-module--ar-h1-mobile--NHhe3";
export var arH2 = "ArHero-module--ar-h2--0Ewzy";
export var arH2Mobile = "ArHero-module--ar-h2-mobile--e9dwd";
export var arH3 = "ArHero-module--ar-h3--nX3R2";
export var arH3Mobile = "ArHero-module--ar-h3-mobile--reiJ-";
export var arH4 = "ArHero-module--ar-h4--YaC83";
export var arH4Mobile = "ArHero-module--ar-h4-mobile--iWr6F";
export var arH5 = "ArHero-module--ar-h5--ejkkT";
export var arH5Mobile = "ArHero-module--ar-h5-mobile--fzae4";
export var arLinkDesktop = "ArHero-module--ar-link-desktop--KRH8s";
export var arLinkMobile = "ArHero-module--ar-link-mobile--CcdT5";
export var arParagraphBase = "ArHero-module--ar-paragraph-base--j6XoD";
export var arParagraphBaseMedium = "ArHero-module--ar-paragraph-base-medium--gb2Nq";
export var arParagraphSmall = "ArHero-module--ar-paragraph-small--Cw1An";
export var arParagraphSmallMedium = "ArHero-module--ar-paragraph-small-medium--m1ZY1";
export var arParagraphXl = "ArHero-module--ar-paragraph-xl--e9VjI";
export var arParagraphXlMedium = "ArHero-module--ar-paragraph-xl-medium--MsAPx";
export var arParagraphXsmall = "ArHero-module--ar-paragraph-xsmall--InSbj";
export var arParagraphXsmallMedium = "ArHero-module--ar-paragraph-xsmall-medium---3Bkc";
export var arQuote = "ArHero-module--ar-quote--Pg0j-";
export var arQuoteMobile = "ArHero-module--ar-quote-mobile--4x9gX";
export var arTransition = "ArHero-module--ar-transition--SNFPE";
export var container = "ArHero-module--container--+CAeZ";